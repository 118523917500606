import Header from 'components/layouts/Header';
import Menu from 'components/layouts/Menu';

const Dashboard = () => {
  return (
    <>
      <Menu />
      <div className='container'>
        <Header />
      </div>
    </>
  );
};

export default Dashboard;
