import Pagination from '@mui/material/Pagination';
import Header from 'components/layouts/Header';
import Menu from 'components/layouts/Menu';
import config from 'config';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { TiTick } from 'react-icons/ti';
import { useAppDispatch } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import gather from 'tools/gather';

const Users = () => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [countPage, setCountPage] = useState(1);

  const search = async (e: any) => {
    if (e.key === 'Enter') {
      init(e.target.value);
    }
  };

  const changeActive = async (e: any) => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/admin/user/${e._id}`, true).put({
      ...e,
      isActive: !e.isActive,
    });

    if (result.code === 200) {
      dispatch(successAlert('Done!'));
      init();
    } else {
      dispatch(errorAlert(result.message));
    }

    dispatch(hideLoading());
  };

  const list = () => {
    const rows: React.ReactElement[] = [];

    data.forEach((e: any, i: number) => {
      rows.push(
        <tr key={i}>
          <td>{e.telegram}</td>
          <td>{moment(e.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
          <td className='setting'>
            {/* <span className='penEdit' onClick={() => dispatch(showPopup(<Add data={e} reload={init} />))}>
              <FaPen />
            </span>
            <span onClick={() => dispatch(showPopup(<View data={e} />))}>
              <FaEye />
            </span> */}
            <span className={e.isActive ? 'active' : ''} onClick={() => changeActive(e)}>
              <TiTick />
            </span>
          </td>
        </tr>,
      );
    });

    return rows;
  };

  const init = async (search: string = '') => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/admin/user?page=${currentPage}${search && `&email=${search}`}&limit=50`, true).get();
    if (result.code === 200) {
      setData(result.data.items.data);
      setCurrentPage(result.data.pages.current);
      setCountPage(result.data.pages.count);
    } else {
      dispatch(errorAlert(result.message));
    }

    dispatch(hideLoading());
  };

  useEffect(() => {
    init();
  }, [currentPage]);

  return (
    <>
      <Menu />
      <div className='container'>
        <Header />
        <div className='search'>
          <input name='email' type='email' placeholder='Email' onKeyUp={(e) => search(e)} />
        </div>
        <table className='tableData'>
          <tbody>
            <tr>
              <td>Email</td>
              <td>Date</td>
              <td>Setting</td>
            </tr>
            {list()}
          </tbody>
        </table>
        <Pagination count={countPage} color='primary' onChange={(_e, v) => setCurrentPage(v)} />
      </div>
    </>
  );
};

export default Users;
