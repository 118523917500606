import { FaChartArea, FaGamepad, FaPen, FaUser } from 'react-icons/fa';

const config = {
  apiUrl: process.env.REACT_APP_API_URL!,
  menu: [
    {
      title: 'Dashboard',
      url: '/',
      icon: <FaChartArea />,
    },
    {
      title: 'Games',
      url: '/games',
      icon: <FaGamepad />,
    },
    {
      title: 'Users',
      url: '/users',
      icon: <FaUser />,
    },
    {
      title: 'Operators',
      url: '/operators',
      icon: <FaPen />,
    },
  ],
};

export default config;
