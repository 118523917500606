import config from 'config';
import { useState } from 'react';
import { useAppDispatch } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import { hidePopup } from 'reducer/slices/popupSlice';
import gather from 'tools/gather';

const Add = ({ data = undefined, reload, games }: any) => {
  const dispatch = useAppDispatch();
  const [title, setTitle] = useState(data?.title);
  const [prevGame, setPrevGame] = useState(data?.prevGame);
  const [bets, setBets] = useState(data?.bets || []);

  const setValue = (index: number, field: string, value: string) => {
    setBets((prevState: any) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        [field]: value,
      },
    }));
  };

  const submit = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/admin/game${data ? '/' + data._id : ''}`, true)[data ? 'put' : 'post']({
      title: title,
      prevGame: prevGame,
      bets: Object.values(bets).filter((e: any) => e.name && e.name !== ''),
    });

    if (result.code === 200) {
      dispatch(hidePopup());
      dispatch(successAlert('Done!'));
      reload();
    } else {
      dispatch(errorAlert(result.message ?? 'Not connected to server'));
    }

    dispatch(hideLoading());
  };

  const listBet = () => {
    const rows: React.ReactElement[] = [];

    for (let i = 0; i < 5; i++) {
      rows.push(
        <div key={i} className='parentInp'>
          <div className='inputLogin'>
            <span>Name</span>
            <input type='text' defaultValue={data?.bets[i]?.name} onChange={(e) => setValue(i, 'name', e.target.value)} />
          </div>
          <div className='inputLogin'>
            <span>Bets</span>
            <input type='text' defaultValue={data?.bets[i]?.bets} placeholder='Seprator: *' onChange={(e) => setValue(i, 'bets', e.target.value)} />
          </div>
          <div className='inputLogin'>
            <span>Result</span>
            <input type='text' defaultValue={data?.bets[i]?.result} onChange={(e) => setValue(i, 'result', e.target.value)} />
          </div>
        </div>,
      );
    }

    return rows;
  };

  return (
    <div className='login'>
      <div className='inputLogin'>
        <span>Title</span>
        <input type='text' defaultValue={data?.title} onChange={(e) => setTitle(e.target.value)} />
      </div>
      <div className='inputLogin'>
        <span>Prev Game ID</span>
        <input type='text' defaultValue={data?.prevGame} onChange={(e) => setPrevGame(e.target.value)} />
      </div>
      <div>----- BETS -----</div>
      {listBet()}
      <div className='submit' onClick={() => submit()}>
        Submit
      </div>
    </div>
  );
};

export default Add;
